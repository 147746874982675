<template>
  <div
    style="margin-top: 10px">
    <div v-for="(period, _idx) in periodDates">
      <div v-for="event in period" :key="event.slug" style="padding: 2px;margin: 2px">
        <router-link :to="{path: event.metadata.artist_link}">
          <div class="card" style="border: 1px solid #4a4747;padding: 10px">
            <h3> {{ getMonthShort(period[0].metadata.date + 'T' + '00:00') }}
              {{ getDayNumeric(period[0].metadata.date + 'T' + '00:00') }}
            </h3>
            <ResponsiveImage :alt="event.metadata.title" :image="event.metadata.image_url" clamp hl="100"
                             wl="250"></ResponsiveImage>
            <hr>
            <h4 class="alt-font" style="font-size: 1.4rem">{{ event.title.split('@')[0] }}</h4>
            <p style="margin-top: -20px">{{ event.title.split('@')[1] }}</p>
          </div>
        </router-link>

      </div>
    </div>
  </div>
</template>
<script>
import { eventDateUtils } from '@/common/eventDateUtils';
import ResponsiveImage from '@/components/HeadImage/ResponsiveImage';

export default {
  name: 'DjBioCollection',
  mixins: [eventDateUtils],
  props: {
    'desktop': {
      type: Boolean,
      default: false
    }, 'nye': {
      type: Boolean,
      default: false
    }, 'color': {
      type: String,
      default: '#202020'
    }
  },
  data: () => {
    return {
      showIdx: 0,
      showAction: ''
    };
  },
  computed: {
    djSchedule: {
      get() {
        return this.$store.state.djSchedule.slice(0, 50).filter(x => x.metadata.artist_link && x.metadata.artist_link.match('djs'));
      }
    },
    periodDates: {
      get() {
        let _periodDates = {};
        this.djSchedule.forEach((y) => {
          if (_periodDates[y.metadata.date]) {
            _periodDates[y.metadata.date].push(y);
          } else {
            _periodDates[y.metadata.date] = [];
            _periodDates[y.metadata.date].push(y);
          }
        });
        return _periodDates;
      }
    }
  },
  created: function() {
    this.$store.dispatch('getDjSchedule');
  },

  components: {
    ResponsiveImage,
  }
};
</script>
