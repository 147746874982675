<template>
  <div class="content">
    <article
      class="row"
      style="margin-top: 10px; background: linear-gradient(#1f1f1f,black,#1f1f1f)"
      :style="{ borderTop: `2px solid ${this.secondaryColor}` }"
    >

      <section class="col-lg-8 col-sm-12">


        <div class="text-left" style=";background-color: #1a1818 !important;z-index:-1;position: static">

          <ResponsiveImage :image="bioImage" hl="300" wl="600" hs="300" ws="300"></ResponsiveImage>
          <h1  class="tulum-party-title-alt" style="font-size: 4rem"> {{artist.title}}</h1>
          <p style="margin-top: -25px;" >{{artist.metadata.headline}}</p>

          <br>
        </div>

        <section
                 style="border-bottom: 1px solid black;">

          <div class="card" style="display: inline-block">
            <a v-if="artist.metadata.spotify_id"
               style="padding: 10px"
               :href="'https://open.spotify.com/artist/' + artist.metadata.spotify_id" target="_blank">
              <img v-lazy="spotifyLogo" height="30px" width="30px" style="border-radius: 60px">
            </a>
            <a v-if="artist.metadata.instagram"
               :href="artist.metadata.instagram" target="_blank" style="padding: 10px">
              <img v-lazy="instagramLogo" height="30px" width="30px" style="border-radius: 60px">
            </a>
            <a v-if="artist.metadata.soundcloud"
               :href="artist.metadata.soundcloud" target="_blank" style="padding: 10px">
              <img v-lazy="soundcloudLogo" height="30px" width="30px" style="border-radius: 60px">
            </a>
          </div>

          <div class="text-left" v-html="artist.content" style="padding: 10px;line-height: 1.6rem !important;"></div>
        </section>


        <div
          v-if="incomingParties[0]"
          class=" text-center"
          style="padding: 10px"
          :style="{
            background: `linear-gradient(${this.secondaryColor}, black)`,
          }"
        >
          <IncomingParty :incoming-party="incomingParties[0]"
                         class="centered"
                         :logo="this.bioImage"
                         :color="this.secondaryColor"></IncomingParty>
        </div>
        <UpcomingEvents :club-color="thirdColor" :incoming-club-parties="incomingParties.slice(0,incomingParties.length)"></UpcomingEvents>


        <section :style="{ borderTop: `2px solid ${this.secondaryColor}` }"
                 class="card text-justify"
                 style=" color: #fff !important; margin-bottom: 1px; padding: 10px; margin-top: 20px; ">
          <h4 class="section-header">About</h4>
          <div v-html="artist.metadata.short"></div>

        </section>
        <section v-if="pastParties.length" :style="{ borderTop: `2px solid ${this.secondaryColor}` }"
                 style=" color: #fff !important; margin-bottom: 1px; padding: 10px; margin-top: 20px; ">
          <h4 v-if="incomingParties" class="tulum-party-subtitle">PAST EVENTS</h4>
          <p style="margin-top: -20px;font-size: 11px;color:silver !important;">Tulum, Mexico</p>
          <div id="past-parties" v-if="pastParties" style="margin-top: 20px">


            <div class="text-left" v-if="pastParties.length >= 6">

              <router-link
                :key="event.slug"
                :to="{ name: 'Event', params: { slug: event.slug } }"
                v-for="event in pastParties"
              >
                <img
                  class="carrousel-image"
                  v-lazy="
                    event.metadata.main_image.imgix_url + '?w=60&h=60&fit=clamp'
                  "
                  :alt="'PAST EVENTS - ' + event.title"
                  height="60px"
                  width="60px"
                />
              </router-link>
            </div>
            <div class="text-left" v-if="pastParties.length < 6">
              <hr>

              <MiniEventCard
                v-bind:event="event"
                with-venue="true"
                v-for="(event, idx) in pastParties"
                class="card-animation-on-hover animation-transition-general animate-bounce"
                short="true"
                past="true"
                :key="event._id"
                :idx="idx"
              ></MiniEventCard>
            </div>
          </div>
        </section>
        <section :style="{ borderTop: `2px solid ${this.secondaryColor}` }"
                 style=" color: #fff !important; margin-bottom: 1px; padding: 10px; margin-top: 20px; ">
          <h4 class="section-header" v-if="artist.metadata.spotify_id">Top Tracks</h4>

          <p
            :style="{ backgroundColor: `${this.secondaryColor}` }"
            style="margin-top: -15px;font-size: 0.7rem;color: black !important;"
            v-if="artist.metadata.spotify_listeners">Monthly Listeners:
            {{ artist.metadata.spotify_listeners.toLocaleString() }}</p>
          <br v-if="artist.metadata.spotify_listeners">
          <iframe style="border-radius:12px;" v-if="artist.metadata.spotify_id"
                  :src="`https://open.spotify.com/embed/artist/${artist.metadata.spotify_id}?utm_source=generator&theme=0`"
                  width="100%" height="200" frameBorder="0" allowfullscreen=""
                  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                  loading="lazy"></iframe>
        </section>

        <section v-if="artist.metadata.videoid"
                 :style="{ borderTop: `2px solid ${this.secondaryColor}` }"
                 style=" color: #fff !important; margin-bottom: 1px; padding: 10px; margin-top: 20px; min-height: 400px;">

          <h4 class="section-header">VIDEO</h4>

          <div id="artist-video" class="card">
            <video-card :video-id="artist.metadata.videoid"
                        mtv
                        :title="artist.metadata.videotitle"
                        :caption="artist.metadata.videocaption">
            </video-card>
          </div>

        </section>
        <SmartMenu :links="links"
                   :logo="bioImage"
                   :with-rsvp="false"
                   :primary-color="primaryColor">
        </SmartMenu>

      </section>
      <aside class=" col-sm-4 col-lg-4 d-none d-lg-inline text-left">
        <h3 class="section-header">
          <img v-lazy="logo" style="border: 1px solid rgb(97, 234, 200);
          padding: 2px;
          border-radius: 400px" height="30px">
          Upcoming

        </h3>

        <HotThisWeek primary-color="#4a4747"  align="text-center"></HotThisWeek>

        <router-link :to="{path: '/tulum-dj-schedule'}">

        <img
          v-lazy="this.djImage"
          alt="A DJ presenting the brand links & resources"
          height="100px"
          width="50px"
          class="pull-right"
          style="margin-top:  -50px;right: 10px;position: static"
        />
        </router-link>
        <p style="font-size: 0.8rem">
          <br>Few places truly square up to Tulum in terms of its exclusivity and range of prolific high-profile Djs and Producers showing up in town week by week .
        </p>
        <hr>

        <DjBioCollection></DjBioCollection>

      </aside>

    </article>
  </div>
</template>

<script>
import moment from "moment";
import VideoCard from '@/components/Cards/VideoCard'
import SmartMenu from "@/components/SmartMenu/SmartMenu";
import MiniEventCard from "@/components/EventCard/MiniEventCard";
import IncomingParty from "@/components/UpcomingEvents/IncomingParty";
import HotThisWeek from "@/pages/TulumDjSchedule/HotThisWeek";
import UpcomingEvents from "@/components/UpcomingEvents/UpcomingEvents";
import ResponsiveImage from '@/components/HeadImage/ResponsiveImage';
import DjBioCollection from '@/components/Cards/DjBioCollection';
import { slugUtils } from '@/common/slugUtils';

export default {
  name: 'Artist',
  props: ['slug'],
  mixins: [slugUtils],
  metaInfo() {
    const ArtistName = this.getTitleFromSlug(this.slug);
    return {
      title: `${ArtistName} · Artist Profile & Events`,
      meta: [
        {
          name: 'description',
          content: `${this.artist.title}`,
        },
        {
          name: 'keywords',
          content: `${this.artist.title}. ${this.artist.metadata.headline}`,
        },
        {
          property: 'og:title',
          content: `${this.artist.title}`,
        },
        {
          property: 'og:url',
          content: `https://tulum.party/djs/${this.slug}`,
        },
        {
          property: 'og:description',
          content: `${this.artist.title}. ${this.artist.metadata.caption}`,
        },
        {property: 'og:type', content: 'article'},

      ],
    };
  },
  data: () => {
    return {
      djImg: "https://imgix.cosmicjs.com/9f39a6a0-c70a-11ee-9ce5-59949019255e-dj.png",
      table: [],
      primaryColor: '#1c1a1a',
      secondaryColor: '#302d2c',
      thirdColor: '#e88659',
      djImage:
        'https://imgix.cosmicjs.com/9f39a6a0-c70a-11ee-9ce5-59949019255e-dj.png',
      logo: 'https://imgix.cosmicjs.com/9c4cf3d0-e0c6-11ef-bee4-3bb1d3c55332-tp-logo-white.png',
      spotifyLogo:   'https://imgix.cosmicjs.com/dab2a390-eab3-11ee-b074-b5c8fe3ef189-spotify.jpg',
      instagramLogo: 'https://imgix.cosmicjs.com/6d099b90-5c09-11ec-a8a3-53f360c99be6-instagram.png',
      soundcloudLogo: 'https://imgix.cosmicjs.com/9c356f90-eab2-11ee-b074-b5c8fe3ef189-Danleech-Simple-Soundcloud.1024.png'
    };
  },
  created: function () {
    this.$store.dispatch('getArtist', {slug: this.slug});
    this.$store.dispatch('getLinks', {slug: 'tulum-nightlife'});
  },
  computed: {
    artist: {
      get() {
        return this.$store.state.artist;
      },
      links: {
        get() {
          return (
            this.$store.state.links || []
          );
        },
      },
    },
    nextEventDate: {
      get() {
        moment()
        return this.incomingParties[0] && moment(this.incomingParties[0].metadata.date).calendar(null, {
          sameDay: '[Today]',
          nextDay: '[Tomorrow]',
          nextWeek: 'dddd',
          sameElse: 'DD/MM/YYYY'
        })
      }
    },
    links: {
      get() {
        return (
          this.$store.state.links || []
        );
      },
    },
    bioImage: {
      get() {
        return this.$store.state.artist.metadata?.main_image?.imgix_url || 'https://imgix.cosmicjs.com/a47ded40-484a-11ee-89ab-17371fc03105-image.png';
      }
    },

    incomingParties: {
      get() {
        let parties = this.$store.state.artist.metadata.parties || [];

        function isIncomingParty(x) {
          let partyDateEndOfDay = moment.utc(x.metadata.date).endOf('day');
          let now = moment().startOf('day');
          return partyDateEndOfDay >= now;
        }

        return [...parties]
          .sort((a, b) => {
            return moment.utc(a.metadata.date + ' ' + a.metadata.start_time) >
            moment.utc(b.metadata.date + ' ' + b.metadata.start_time)
              ? 1
              : -1;
          })
          .filter((x) => isIncomingParty(x));
      },
    },
    pastParties: {
      get() {
        let parties = this.$store.state.artist.metadata.parties || [];

        function compareUTCTimes(x) {
          let partyDateEndOfDay = moment.utc(x.metadata.date).endOf('day');
          let now = moment().startOf('day');
          return partyDateEndOfDay < now;
        }

        return [...parties]
          .sort((a, b) => {
            return a.metadata.date < b.metadata.date ? 1 : -1;
          })
          .filter((x) => compareUTCTimes(x));
      },
    },
  },

  components: {
    DjBioCollection,
    ResponsiveImage,
    UpcomingEvents,
    HotThisWeek,
    IncomingParty,
    MiniEventCard,
    VideoCard,
    SmartMenu,
  },
};
</script>

